import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
export default function UserFlycrickFlightCardButtons({ departureTime, flightId, betCount }) {
  const [timeOff, setTimeOff] = useState(4);
  useEffect(() => {
    const intervalId = setInterval(() => {
      const flighttime = new Date(departureTime);
      const current = new Date();
      const timeDiff = flighttime - current;
      const lendingdate = new Date(flighttime);
      lendingdate.setSeconds(lendingdate.getSeconds() + 90);
      if (lendingdate < current) {
        setTimeOff(3); 
      } else if (timeDiff >= 0 && timeDiff <= 120000) {
        setTimeOff(1); 
      } else if (timeDiff <= 0 && current < lendingdate) {
        setTimeOff(2); 
      } else {
        setTimeOff(0); 
      }
    }, 1000);
    return () => clearInterval(intervalId);
  }, [departureTime]);

  return (
    <>
      {timeOff == 0 ? (
        <>
          {betCount != 0 ? (
            <span className="btn  button-9 btn-success  btn-sm"> Booked {betCount < 10 ? "0" + betCount : betCount} </span>
          ) : null}

          <Link
            to={`/user/random-flight/bet-plane/${flightId}`}
            className="text-center border-0 pt-1"
          >
            <span className="btn btn-block button-9 btn-primary  btn-sm">
              {betCount ? <i class="bi bi-plus-lg"></i> : "Book Now"}
            </span>
          </Link>
        </>
      ) : null}
      {timeOff == 3 ? (
        <Link className="text-center border-0 pt-1"><span className="btn btn-block button-9 btn-success  btn-sm">Landed</span></Link>
      ) : null}
      {timeOff == 2 ? (
        <Link className="text-center border-0 pt-1">
          <span className="btn btn-block button-9 btn-danger  btn-sm">Flight Tackoff</span>
        </Link>
      ) : null}
      {timeOff == 1 ? (
        <Link className="text-center border-0 pt-1">
          <span className="btn btn-block button-9 btn-danger  btn-sm">Booking Closed</span>
        </Link>
      ) : null}
    </>
  );
}
