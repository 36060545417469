import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import UserFlycrickTimeDifference from "../user-component/User.Time.Difference";
import UserFlycrickBookingModal from "../user-component/User.Booking.Modal";
import token from "../../../../../Helper/Token";
export default function UserFlycrickBetPlane() {
  const [flightData, setFlightData] = useState({});
  const { flightId } = useParams();
  const [timeOff, setTimeOff] = useState(4);
  const [flightBetFormData, setFlightBetFormData] = useState({
    fromPlace: null,
    toPlace: null,
    flightId: null,
    destinationId: null,
    betAmount: null,
  });

  useEffect(() => {
    setFlightBetFormData((prev) => ({ ...prev, flightId }));
    fetchFlightData();
  }, []);

  async function fetchFlightData() {
    token.get(`/random-flight/single-flight/${flightId}`).then((res)=>{
      if (res.data.status === "success") {
        const data = res.data.data;
        setFlightData(res.data.data);
        setFlightBetFormData((prev) => ({...prev,fromPlace: data.fromPlace}));
      } else {
        toast.error(res.data.message);
      }
    }).catch ((error)=>{ toast.error("Connection issue");})
  }
  useEffect(() => {
    const intervalId = setInterval(() => {
      const flighttime = new Date(flightData.departureTime);
      const current = new Date();
      const timeDiff = flighttime - current;
      const lendingdate = new Date(flighttime);
      lendingdate.setSeconds(lendingdate.getSeconds()+90);
      if(lendingdate<current){
        setTimeOff(3)
      }else if (timeDiff >= 0 && timeDiff <= 120000 ) {
        setTimeOff(1)
      }else if(timeDiff <= 0 && current < lendingdate){
        setTimeOff(2)
      }else{
        setTimeOff(0)
      }

    }, 1000);
    return () => { clearInterval(intervalId); };

  }, [flightData]);
  return (
    <>
      <UserFlycrickBookingModal
        setFlightBetFormData={setFlightBetFormData}
        flightBetFormData={flightBetFormData}
        fetchFlightData={fetchFlightData}
      />

      <div class="content-body">
        <div class="container-fluid">
          <div class="row">
            <div class="col-xl-12 col-xxl-12">
              <div class="row quizee">
                {flightData?.destinationCities?.map((elem, index) => {
                  return (
                    <div class="col-md-4 col-sm-4 col-12">
                      <div class="card card-box pull-up">
                        <div class="card-header   border-0">
                          <h6 class="mb-0">
                            <img
                              src="/favicon/flighticon.png"
                              className="planelogo"
                              alt=""
                            />
                             {flightData?.flightNumber}{" "}
                          </h6>


                          <h6 class="mb-0 bg-success p-1 border">
                            {flightData?.destinationCities[index]
                              ?.destinationNumber < 10
                              ? "0" +
                                flightData?.destinationCities[index]
                                  ?.destinationNumber
                              : flightData?.destinationCities[index]
                                  ?.destinationNumber}{" "}
                          </h6>


                          
                        </div>
                        <div class="card-body  pt-3 px-3">
                          <div class="row alig">
                            <div class="col">
                              <span class="text-light">From</span>
                              <div class=" border-0 text-light pt-1 fs-16 font-w600">
                                {flightData?.fromPlace}
                              </div>
                            </div>
                            <div class="col text-center">
                              <span class="text-light mt-2">
                                <img
                                  src="/airplane.png"
                                  className="fromplane"
                                  alt=""
                                />
                              </span>
                              <div class=" border-0 pt-1">
                                <UserFlycrickTimeDifference
                                  departureTime={flightData?.departureTime}
                                />
                              </div>
                            </div>
                            <div class="col text-end  ">
                              <span class="text-light">To</span>
                              <div class="border-0 text-light pt-1 fs-16 font-w600">
                                {elem?.destination}
                              </div>
                            </div>
                          </div>
                          {timeOff==3? <span class="btn btn-block button-9 btn-success btn-sm mt-2">Lended</span> 
                          : timeOff==1? <span class="btn btn-block button-9 btn-danger btn-sm mt-2"> Booking Closed</span>: 
                          timeOff==2?<span class="btn btn-block button-9 btn-danger btn-sm mt-2">Tackoff</span>:
                          timeOff==0?<div class="text-center border-0 pt-1 mt-2" onClick={() => { document.getElementById("User_Flycrick_Booking_Modal").click();  setFlightBetFormData((prev) => ({ ...prev, toPlace: elem?.destination,destinationId: elem._id}));}}>
                            <span class="btn btn-block button-9 btn-success btn-sm mt-2">Book Ticket </span>
                          </div>
                          :""
                          }
                          <div className="Departure">
                            <i class="bi bi-clock me-1"></i> Total Booked
                            Tickets{" "}
                            <span className="text-end text-success">
                              {" "}
                              {elem?.totalBets < 10
                                ? " 0" + elem?.totalBets
                                : " " + elem?.totalBets}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
