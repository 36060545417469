import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import token from "../../../Helper/Token";
import {
  getUserIDFromToken,
  humanReadableTimeAndDate,
  isNumeric,
} from "../../../Helper/function";
import { socket } from "../../../Helper/Variables";
import TablePlaceholder from "../../Components/TablePlaceholder";

const Dice = () => {

  const [dummyData, setDummyData] = useState([]);


  const [diceLoading, setDiceLoading] = useState(false);
  const [timer, setTimer] = useState(30);
  const [gameObj, setGameObj] = useState({
    player1: "",
    player2: "",
    player3: "",
    player4: "",
    score1: 0,
    score2: 0,
    score3: 0,
    score4: 0,
    amount: 0,
    roll1: 0,
    roll2: 0,
    roll3: 0,
    roll4: 0,
    activePlayer: "",
    activePlayerNum: "",
    dice1num: 6,
    dice2num: 6,
    winner: "",
    isGameOver: 1,
  });
  const [amount, setAmount] = useState(10);
  const [totalPlayers, setTotalplayers] = useState(0);
  const [amountValidation, setAmountValidation] = useState("");
  const [isOpenWallet, setIsOpenWallet] = useState(false);
  const [dashboardData, setDashboardData] = useState({
    availableBalance: 0.0,
    activeGame: "",
  });
  const [onlinePlayers, setOnlinePlayers] = useState([]);
  const [onlineMembers, setOnlineMembers] = useState([]);
  const [filteredPlayers, setFilteredPlayers] = useState([]);
  const [filteredFriends, setFilteredFriends] = useState([]);
  const [balanceKMT, setBalanceKMT] = useState(0.0);
  const [gameRecord, setGameRecord] = useState([]);
  const [loadingTable, setLoadingTable] = useState(false);
  const [walletType, setWalletType] = useState("gameWallet");

  const [suffleData, setSuffleData] = useState(true);


  useEffect(() => {
    setTimeout(() => {
     // fetchRecentWinners();

      setSuffleData(!suffleData);
    }, 1500);
  }, [suffleData]);

  useEffect(() => {
    setLoadingTable(true);
    token
      .get("/dice/game-record")
      .then((res) => {
        if (res.data.status == "success") {
          setGameRecord(res.data.data);
        } else {
          toast.error(res?.data?.message, {
            position: "top-right",
            autoClose: 900,
          });
        }
        setLoadingTable(false);
      })

      .catch((err) => {
        toast.error("Internal Server Error.", {
          position: "top-right",
          autoClose: 900,
        });
      })
      .finally(() => {
        setLoadingTable(false);
      });
  }, []);

  useEffect(() => {
    fetchAccountBalance();
    fetchGameData();
    //fetchRecentWinners();
  }, []);

  useEffect(() => {
    if (
      gameObj?.activePlayer == getUserIDFromToken() &&
      gameObj?.player1?.name &&
      gameObj?.player2?.name &&
      gameObj?.player3?.name &&
      gameObj?.player4?.name &&
      !gameObj?.isGameOver
    ) {
      if (timer > 0) {
        setTimeout(() => {
          setTimer(timer - 1);

          if (timer == 1) {
            document?.getElementById("rollDice")?.click();
            setTimer(30);
          }
        }, 1000);
      }
    }
  }, [gameObj.activePlayer, timer]);

  useEffect(() => {
    if (sessionStorage.getItem("KMT_Token")) {
      fetchKMTBalance();
    }
  }, []);

  useEffect(() => {
    if (gameObj.isGameOver && gameObj?.winner) {
      document?.getElementById("open-winner-banner")?.click();

      setTimeout(() => {
        setGameObj({
          player1: "",
          player2: "",
          player3: "",
          player4: "",
          score1: 0,
          score2: 0,
          score3: 0,
          score4: 0,
          amount: 0,
          roll1: 0,
          roll2: 0,
          roll3: 0,
          roll4: 0,
          activePlayer: "",
          activePlayerNum: "",
          dice1num: 6,
          dice2num: 6,
          winner: "",
          isGameOver: 1,
        });
        setTotalplayers(0);
        document?.getElementById("close-winner-banner")?.click();
      }, 3000);
    }
  }, [gameObj?.winner]);

  useEffect(() => {
    socket.on("broadcast_deleted-game", () => {
      setGameObj({
        player1: "",
        player2: "",
        player3: "",
        player4: "",
        score1: 0,
        score2: 0,
        score3: 0,
        score4: 0,
        amount: 0,
        roll1: 0,
        roll2: 0,
        roll3: 0,
        roll4: 0,
        activePlayer: "",
        activePlayerNum: "",
        dice1num: 6,
        dice2num: 6,
        winner: "",
        isGameOver: 1,
      });

      toast.error("Game deleted", {
        position: "top-right",
        autoClose: 900,
      });
    });

    return () => {
      socket.off("broadcast_deleted-game");
    };
  }, []);

  useEffect(() => {
    socket.on("broadcast_player", (gameObj) => {
      fetchGameData();
    });
    return () => {
      socket.off("broadcast_player");
    };
  }, []);

  useEffect(() => {
    socket.on("broadcast-game-droped", () => {
      fetchGameData();
    });
    return () => {
      socket.off("broadcast-game-droped");
    };
  }, []);

  socket.on("broadcast_score", (data) => {
    if (data) {
      setGameObj({
        ...data,
        dice1num: data?.num1,
        dice2num: data?.num2,
      });

      wallet();
    }
    return () => {
      socket.off("broadcast_score");
    };
  });

  function fetchGameData() {
    token
      .get("/dice/game-data")
      .then((res) => {
        if (res.data.status == "success") {
          if (!res?.data?.data?.isGameOver) {
            setGameObj(res.data.data);
          }
        } else {
          setGameObj({
            player1: "",
            player2: "",
            player3: "",
            player4: "",
            score1: 0,
            score2: 0,
            score3: 0,
            score4: 0,
            amount: 0,
            roll1: 0,
            roll2: 0,
            roll3: 0,
            roll4: 0,
            activePlayer: "",
            activePlayerNum: "",
            dice1num: 6,
            dice2num: 6,
            winner: "",
            isGameOver: 1,
          });
        }
      })

      .catch((err) => {
        toast.error("Internal Server Error.", {
          position: "top-right",
          autoClose: 900,
        });
      });
  }

  async function fetchKMTBalance() {
    try {
      const res = await fetch("https://apikmt.mnbsoft.co.in/api/gamebalance", {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": sessionStorage.getItem("KMT_Token"),
        },
      });

      const data = await res.json();

      setBalanceKMT(data?.data?.balance);
    } catch (error) {
      toast.error("Internal Server Error.", {
        position: "top-right",
        autoClose: 900,
      });
    }
  }


  useEffect(()=>{
    socket.on('recentWinners',(data)=>{
      setDummyData(data);
      setSuffleData(!suffleData);
    })
  },[])
  function fetchRecentWinners() {
    token.get("/dice/recentWinners").then((res) => {
        if (res.data.status == "success") {
          setDummyData(res?.data?.data);
        } else {
          toast.error(res?.data?.message, {
            position: "top-right",
            autoClose: 900,
          });
        }
      })

      .catch((err) => {
        toast.error("Internal Server Error.", {
          position: "top-right",
          autoClose: 900,
        });
      });
  }



  function fetchAccountBalance() {
    token
      .get("/user/dashboard")
      .then((res) => {
        if (res.data.status == "success") {
          setDashboardData(res.data.data);
        } else {
          toast.error(res?.data?.message, {
            position: "top-right",
            autoClose: 900,
          });
        }
      })

      .catch((err) => {
        toast.error("Internal Server Error.", {
          position: "top-right",
          autoClose: 900,
        });
      });
  }

  const wallet = () => {
    setIsOpenWallet(!isOpenWallet);
  };

  async function deductAmountFromKMT() {
    try {
      const res = await fetch(
        "https://apikmt.mnbsoft.co.in/api/deductbalance",
        {
          method: "post",
          headers: {
            "Content-Type": "application/json",
            "X-Auth-Token": sessionStorage.getItem("KMT_Token"),
          },
          body: JSON.stringify({
            balance: amount,
          }),
        }
      );
      const data = await res.json();

      if (data?.status == "success") {
        addAmountInGameWallet(amount);
      } else {
        toast.error(data?.message, {
          position: "top-right",
          autoClose: 900,
        });
        return null;
      }
    } catch (error) {
      toast.error("Internal Server Error.", {
        position: "top-right",
        autoClose: 900,
      });
    }
  }

  function addAmountInGameWallet() {
    token
      .put("/user/update-available-balance", { amount: amount })
      .then((res) => {
        // if (res.data.status == "success") {
        //   toast.success(res.data.message, {
        //     position: "top-left",
        //     autoClose: 900,
        //   });
        // } else {
        //   toast.error(res.data.message, {
        //     position: "top-right",
        //     autoClose: 900,
        //   });
        // }
      })

      .catch((err) => {
        toast.error("Internal Server Error.", {
          position: "top-right",
          autoClose: 900,
        });
      });
  }




  function createGame() {
    if (walletType == "kmtWallet" && sessionStorage.getItem("KMT_Token")) {
      deductAmountFromKMT();
    }

    if (totalPlayers < 2) {
      toast.error("Please select the number of players", {
        position: "top-right",
        autoClose: 900,
      });
      return;
    }

    token
      .post("/dice/set-amount", { amount: amount, totalPlayers: totalPlayers })
      .then((res) => {
        if (res.data.status == "success") {
          toast.success(res.data.message, {
            position: "top-left",
            autoClose: 900,
          });

          fetchGameData();
          fetchKMTBalance();
          fetchAccountBalance();
        } else {
          toast.error(res.data.message, {
            position: "top-right",
            autoClose: 900,
          });
        }
      })

      .catch((err) => {
        toast.error("Internal Server Error.", {
          position: "top-right",
          autoClose: 900,
        });
      });
  }






  function fetchOnlinePlayers(e) {
    token
      .get("/user/get-online-players")
      .then((res) => {
        if (res.data.status == "success") {
          setOnlinePlayers(res?.data?.data);
          setFilteredPlayers(res?.data?.data);
        } else {
          toast.error(res?.data?.message, {
            position: "top-right",
            autoClose: 900,
          });
        }
      })

      .catch((err) => {
        toast.error("Internal Server Error.", {
          position: "top-right",
          autoClose: 900,
        });
      });
  }

  function fetchOnlineMembers(e) {
    token
      .get("/user/get-online-member")
      .then((res) => {
        if (res.data.status == "success") {
          setOnlineMembers(res?.data?.data);
          setFilteredFriends(res?.data?.data);
        } else {
          toast.error(res?.data?.message, {
            position: "top-right",
            autoClose: 900,
          });
        }
      })

      .catch((err) => {
        toast.error("Internal Server Error.", {
          position: "top-right",
          autoClose: 900,
        });
      });
  }





  function inviteToPlay(recieverId) {
    token
      .post("/dice/send-request", { recieverId: recieverId })
      .then((res) => {
        if (res.data.status == "success") {
          toast.success(res.data.message, {
            position: "top-left",
            autoClose: 900,
          });
          if (res.data.message == "Request sent successfully") {
            socket.emit("requestSend", res?.data?.data?._id);
          }
        } else {
          toast.error(res.data.message, {
            position: "top-right",
            autoClose: 900,
          });
        }
      })

      .catch((err) => {
        toast.error("Internal Server Error.", {
          position: "top-right",
          autoClose: 900,
        });
      });
  }

  function rollDice() {
    setDiceLoading(true);
    setTimer(31);

    token
      .get("/dice/roll-dice")
      .then((res) => {
        if (res.data.status == "success") {
          setGameObj(res.data.data);
          toast.success(res.data.message, {
            position: "top-left",
            autoClose: 900,
          });
          wallet();

          socket.emit("rollDice", res?.data?.data?._id);
        } else {
          toast.error(res.data.message, {
            position: "top-right",
            autoClose: 900,
          });
        }
        setDiceLoading(false);
      })
      .catch((err) => {
        toast.error("Internal Server Error.", {
          position: "top-right",
          autoClose: 900,
        });
        setDiceLoading(false);
      });
  }

  function getTotalNumber() {
    if (getUserIDFromToken() == gameObj?.player1?._id) {
      return gameObj?.score1;
    } else if (getUserIDFromToken() == gameObj?.player2?._id) {
      return gameObj?.score2;
    } else if (getUserIDFromToken() == gameObj?.player3?._id) {
      return gameObj?.score3;
    } else {
      return gameObj?.score4;
    }
  }

  function getBetNumber() {
    if (getUserIDFromToken() == gameObj?.player1?._id) {
      return gameObj?.roll1;
    } else if (getUserIDFromToken() == gameObj?.player2?._id) {
      return gameObj?.roll2;
    } else if (getUserIDFromToken() == gameObj?.player3?._id) {
      return gameObj?.roll3;
    } else {
      return gameObj?.roll4;
    }
  }

  function searchOnlinePlayersbyId(e) {
    const arr = onlinePlayers;
    const filteredArray = arr.filter(
      (player) =>
        player.userId.toUpperCase().indexOf(e.target.value.toUpperCase()) == 0
    );

    setFilteredPlayers(filteredArray);
  }

  function searchOnlineMembersbyId(e) {
    const arr = onlineMembers;
    const filteredArray = arr.filter(
      (player) =>
        player.userId.toUpperCase().indexOf(e.target.value.toUpperCase()) == 0
    );

    setFilteredFriends(filteredArray);
  }

  function deleteGame() {
    token
      .delete("/dice/delete-game")
      .then((res) => {
        if (res.data.status == "success") {
          toast.success(res?.data?.message, {
            position: "top-left",
            autoClose: 900,
          });
          fetchGameData();
          fetchKMTBalance();
          fetchAccountBalance();
          socket.emit("game-deleted", res?.data?.data);
        } else {
          toast.error(res?.data?.message, {
            position: "top-right",
            autoClose: 900,
          });
        }
      })

      .catch((err) => {
        toast.error("Internal Server Error.", {
          position: "top-right",
          autoClose: 900,
        });
      });
  }

  function dropGame() {
    token
      .put("/dice/drop-game", {})
      .then((res) => {
        if (res?.data?.status == "success") {
          toast?.success(res?.data?.message, {
            position: "top-left",
            autoClose: 900,
          });
          fetchGameData();
          fetchKMTBalance();
          fetchAccountBalance();
          socket.emit("game-droped", res?.data?.data);
        } else {
          toast.error(res?.data?.message, {
            position: "top-right",
            autoClose: 900,
          });
        }
      })

      .catch((err) => {
        toast.error("Internal Server Error.", {
          position: "top-right",
          autoClose: 900,
        });
      });
  }

  return (
    <>
      <div class="page-titles">
        <div className="container-fluid">
          <div class="sub-dz-head">
            <div class="d-flex align-items-center dz-head-title">
              <h2 class="text-white m-0">My Account</h2>
            </div>
          </div>
        </div>
      </div>

      <div class="content-body">
        <div class="container-fluid">
          <div class="row ">
            <div class="col-lg-9">
              <div class="row ">
                <div class="col-lg-12">
                  <div class="card">
                    <div class="card-header">
                      <h5 class="card-title">
                        Timer : {timer < 10 ? "0" : null}
                        {timer} Sec
                      </h5>

                      <h5 class="card-title">
                        Game Balance{" "}
                        {dashboardData?.availableBalance.toFixed(2)} INR
                      </h5>

                      {sessionStorage.getItem("KMT_Token") ? (
                        <h5 class="card-title">
                          KMT Balance {balanceKMT?.toFixed(2)} INR
                        </h5>
                      ) : null}

                      <button
                        class="btn btn-success btn-sm"
                        data-bs-toggle="modal"
                        data-bs-target="#ForInvite"
                      >
                        Invite Players
                      </button>

                      <button
                        className="btn btn-success btn-sm d-none"
                        id="open-winner-banner"
                        data-bs-toggle="modal"
                        data-bs-target="#Result"
                      >
                        Result
                      </button>
                    </div>

                    <div class="card-body">
                      <>
                        <div class="mt-0 mb-4 d-flex justify-content-between">
                          {totalPlayers >= 1 || gameObj?.totalPlayers >= 1 ? (
                            <>
                              {gameObj?.activePlayerNum == 1 &&
                                gameObj?.player1?.isOnline ? (
                                <span class="btn btn-success btn-xs light text-uppercase">
                                  {gameObj?.player1?.name
                                    ? gameObj?.player1?.name
                                    : "Player 1"}
                                </span>
                              ) : null}

                              {gameObj?.activePlayerNum != 1 &&
                                gameObj?.player1?.isOnline ? (
                                <span class="btn btn-warning btn-xs light text-uppercase ">
                                  {gameObj?.player1?.name
                                    ? gameObj?.player1?.name
                                    : "Player 1"}
                                </span>
                              ) : null}

                              {!gameObj?.player1?.isOnline ? (
                                <span class="btn btn-danger btn-xs light text-uppercase ">
                                  {gameObj?.player1?.name
                                    ? gameObj?.player1?.name
                                    : "Player 1"}
                                </span>
                              ) : null}
                            </>
                          ) : null}

                          {totalPlayers >= 2 || gameObj?.totalPlayers >= 2 ? (
                            <>
                              {gameObj?.activePlayerNum == 2 &&
                                gameObj?.player2?.isOnline ? (
                                <span class="btn btn-success btn-xs light text-uppercase ">
                                  {gameObj?.player2?.name
                                    ? gameObj?.player2?.name
                                    : "Player 2"}
                                </span>
                              ) : null}

                              {gameObj?.activePlayerNum != 2 &&
                                gameObj?.player2?.isOnline ? (
                                <span class="btn btn-warning btn-xs light text-uppercase ">
                                  {gameObj?.player2?.name
                                    ? gameObj?.player2?.name
                                    : "Player 2"}
                                </span>
                              ) : null}

                              {!gameObj?.player2?.isOnline ? (
                                <span class="btn btn-danger btn-xs light text-uppercase ">
                                  {gameObj?.player2?.name
                                    ? gameObj?.player2?.name
                                    : "Player 2"}
                                </span>
                              ) : null}
                            </>
                          ) : null}
                          {totalPlayers >= 3 || gameObj?.totalPlayers >= 3 ? (
                            <>
                              {gameObj?.activePlayerNum == 3 &&
                                gameObj?.player3?.isOnline ? (
                                <span class="btn btn-success btn-xs light text-uppercase ">
                                  {gameObj?.player3?.name
                                    ? gameObj?.player3?.name
                                    : "Player 3"}
                                </span>
                              ) : null}

                              {gameObj?.activePlayerNum != 3 &&
                                gameObj?.player3?.isOnline ? (
                                <span class="btn btn-warning btn-xs light text-uppercase ">
                                  {gameObj?.player3?.name
                                    ? gameObj?.player3?.name
                                    : "Player 3"}
                                </span>
                              ) : null}

                              {!gameObj?.player3?.isOnline ? (
                                <span class="btn btn-danger btn-xs light text-uppercase ">
                                  {gameObj?.player3?.name
                                    ? gameObj?.player3?.name
                                    : "Player 3"}
                                </span>
                              ) : null}
                            </>
                          ) : null}
                          {totalPlayers == 4 || gameObj?.totalPlayers >= 4 ? (
                            <>
                              {gameObj?.activePlayerNum == 4 &&
                                gameObj?.player4?.isOnline ? (
                                <span class="btn btn-success btn-xs light text-uppercase ">
                                  {gameObj?.player4?.name
                                    ? gameObj?.player4?.name
                                    : "Player 4"}
                                </span>
                              ) : null}

                              {gameObj?.activePlayerNum != 4 &&
                                gameObj?.player4?.isOnline ? (
                                <span class="btn btn-warning btn-xs light text-uppercase ">
                                  {gameObj?.player4?.name
                                    ? gameObj?.player4?.name
                                    : "Player 4"}
                                </span>
                              ) : null}

                              {!gameObj?.player4?.isOnline ? (
                                <span class="btn btn-danger btn-xs light text-uppercase ">
                                  {gameObj?.player4?.name
                                    ? gameObj?.player4?.name
                                    : "Player 4"}
                                </span>
                              ) : null}
                            </>
                          ) : null}
                        </div>
                        <div className="clearfix"></div>
                        <div className="mobilbet">
                          <div className="row  mb-4 ">
                            <div className="col-6">
                              <div className="mybox">
                                <span class="fs-14 text-white font-w500">
                                  NO OF BET
                                </span>
                                <a href="/">
                                  <h4 class="font-w600 mb-0">
                                    {getBetNumber()}/4
                                  </h4>
                                </a>
                              </div>
                            </div>
                            <div className="col-6">
                              <div className="mybox">
                                <span class="fs-14 text-white font-w500">
                                  TOTAL NO
                                </span>
                                <a href="/">
                                  <h4 class="font-w600 mb-0">
                                    {getTotalNumber() < 10 ? "0" : null}
                                    {getTotalNumber()}
                                  </h4>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row  mb-4  ">
                          <div class="col-md-4 mt-2 deskbet">
                            <p className="TEXT-BET fs-20 mb-2 font-w600 text-center ">
                              NO OF BET
                            </p>
                            <h1 className="font-w600 bet-no  text-white text-center">
                              {getBetNumber()}/4
                            </h1>
                          </div>

                          <div class="col-md-4 mb-4 mt-2">
                            <div class="d-flex align-items-center">
                              <div
                                id="dice"
                                data-side={gameObj?.dice1num}
                                className={` me-2 ${isOpenWallet ? "roll" : ""
                                  }`}
                              >
                                <div class="shade"></div>
                                <div class="sides side-1">
                                  <span class="dot dot-1"></span>
                                </div>
                                <div class="sides side-2">
                                  <span class="dot dot-1"></span>
                                  <span class="dot dot-2"></span>
                                </div>
                                <div class="sides side-3">
                                  <span class="dot dot-1"></span>
                                  <span class="dot dot-2"></span>
                                  <span class="dot dot-3"></span>
                                </div>
                                <div class="sides side-4">
                                  <span class="dot dot-1"></span>
                                  <span class="dot dot-2"></span>
                                  <span class="dot dot-3"></span>
                                  <span class="dot dot-4"></span>
                                </div>
                                <div class="sides side-5">
                                  <span class="dot dot-1"></span>
                                  <span class="dot dot-2"></span>
                                  <span class="dot dot-3"></span>
                                  <span class="dot dot-4"></span>
                                  <span class="dot dot-5"></span>
                                </div>
                                <div class="sides side-6">
                                  <span class="dot dot-1"></span>
                                  <span class="dot dot-2"></span>
                                  <span class="dot dot-3"></span>
                                  <span class="dot dot-4"></span>
                                  <span class="dot dot-5"></span>
                                  <span class="dot dot-6"></span>
                                </div>
                              </div>

                              <div
                                id="dice"
                                data-side={gameObj?.dice2num}
                                className={`${isOpenWallet ? "roll" : ""}`}
                              >
                                <div class="shade"></div>
                                <div class="sides side-1">
                                  <span class="dot dot-1"></span>
                                </div>
                                <div class="sides side-2">
                                  <span class="dot dot-1"></span>
                                  <span class="dot dot-2"></span>
                                </div>
                                <div class="sides side-3">
                                  <span class="dot dot-1"></span>
                                  <span class="dot dot-2"></span>
                                  <span class="dot dot-3"></span>
                                </div>
                                <div class="sides side-4">
                                  <span class="dot dot-1"></span>
                                  <span class="dot dot-2"></span>
                                  <span class="dot dot-3"></span>
                                  <span class="dot dot-4"></span>
                                </div>
                                <div class="sides side-5">
                                  <span class="dot dot-1"></span>
                                  <span class="dot dot-2"></span>
                                  <span class="dot dot-3"></span>
                                  <span class="dot dot-4"></span>
                                  <span class="dot dot-5"></span>
                                </div>
                                <div class="sides side-6">
                                  <span class="dot dot-1"></span>
                                  <span class="dot dot-2"></span>
                                  <span class="dot dot-3"></span>
                                  <span class="dot dot-4"></span>
                                  <span class="dot dot-5"></span>
                                  <span class="dot dot-6"></span>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="col-md-4 mt-2 deskbet">
                            <p className="TEXT-BET fs-20 mb-2 font-w600 text-center ">
                              TOTAL NO
                            </p>
                            <h1 className="font-w600 bet-no  text-white text-center">
                              {getTotalNumber() < 10 ? "0" : null}
                              {getTotalNumber()}
                            </h1>
                          </div>
                        </div>
                        <hr></hr>

                        <div class="text-center">
                          <div class="row">
                            {totalPlayers >= 1 || gameObj?.totalPlayers >= 1 ? (
                              <>
                                {getUserIDFromToken() !=
                                  gameObj?.player1?._id ? (
                                  <div class="col">
                                    <h3 class="m-b-0 TEXT-BET">
                                      {gameObj?.score1 < 10 ? "0" : null}
                                      {gameObj?.score1}
                                    </h3>
                                    <span className="text-white">
                                      {gameObj?.player1?.name}
                                    </span>
                                  </div>
                                ) : null}
                              </>
                            ) : null}

                            {totalPlayers >= 2 || gameObj?.totalPlayers >= 2 ? (
                              <>
                                {getUserIDFromToken() !=
                                  gameObj?.player2?._id ? (
                                  <div class="col">
                                    <h3 class="m-b-0  TEXT-BET">
                                      {gameObj?.score2 < 10 ? "0" : null}
                                      {gameObj?.score2}
                                    </h3>
                                    <span className="text-white">
                                      {gameObj?.player2?.name}
                                    </span>
                                  </div>
                                ) : null}
                              </>
                            ) : null}

                            {totalPlayers >= 3 || gameObj?.totalPlayers >= 3 ? (
                              <>
                                {getUserIDFromToken() !=
                                  gameObj?.player3?._id ? (
                                  <div class="col">
                                    <h3 class="m-b-0 TEXT-BET">
                                      {gameObj?.score3 < 10 ? "0" : null}
                                      {gameObj.score3}
                                    </h3>
                                    <span className="text-white">
                                      {gameObj?.player3?.name}
                                    </span>
                                  </div>
                                ) : null}
                              </>
                            ) : null}

                            {totalPlayers >= 4 || gameObj?.totalPlayers >= 4 ? (
                              <>
                                {getUserIDFromToken() !=
                                  gameObj?.player4?._id && totalPlayers >= 4 ? (
                                  <div class="col">
                                    <h3 class="m-b-0 TEXT-BET">
                                      {gameObj?.score4 < 10 ? "0" : null}
                                      {gameObj?.score4}
                                    </h3>
                                    <span className="text-white">
                                      {gameObj?.player4?.name}
                                    </span>
                                  </div>
                                ) : null}
                              </>
                            ) : null}
                          </div>

                          {gameObj?.activePlayer == getUserIDFromToken() ? (
                            <div class="mt-4">
                              {!diceLoading ? (
                                <button
                                  type="button"
                                  class="btn  btn-success mb-1"
                                  onClick={rollDice}
                                  id="rollDice"
                                >
                                  Roll Dice
                                </button>
                              ) : (
                                <button
                                  class="btn  btn-success mb-1"
                                  type="button"
                                  disabled
                                >
                                  <span
                                    class="spinner-border spinner-border-sm"
                                    aria-hidden="true"
                                  ></span>
                                  <span role="status">Loading...</span>
                                </button>
                              )}
                            </div>
                          ) : (
                            <button
                              type="button"
                              class="btn  btn-danger mb-1"
                              id="rollDice"
                            >
                              Wait
                            </button>
                          )}
                        </div>
                      </>
                    </div>
                  </div>
                </div>

                <>
                  <div class="col-12">
                    <div class="coin-warpper d-flex align-items-center justify-content-between flex-wrap">
                      <div class="input-group search-area w-auto coin-tab-search mt-xl-0 mt-2">
                        <span class="my-2 fs-16 font-w600  TEXT-BET d-block">
                          BET AMOUNT - {String(amount).padEnd(2, 0)}.00
                        </span>
                      </div>

                      <div>
                        <ul class="nav nav-pills" role="tablist">
                          <li className="me-5 nav-item" role="presentation">
                            <input
                              type="text"
                              className="form-control"
                              value={amount}
                              onChange={(e) => {
                                if (
                                  e.target.value === "" ||
                                  isNumeric(e.target.value)
                                ) {
                                  if (walletType == "gameWallet") {
                                    if (
                                      e.target.value <=
                                      dashboardData?.availableBalance
                                    ) {
                                      setAmount(e.target.value);
                                    } else {
                                      toast.error("Insufficient balance", {
                                        position: "top-right",
                                        autoClose: 900,
                                      });
                                    }
                                  } else {
                                    if (e.target.value <= balanceKMT) {
                                      setAmount(e.target.value);
                                    } else {
                                      toast.error("Insufficient balance", {
                                        position: "top-right",
                                        autoClose: 900,
                                      });
                                    }
                                  }

                                  setAmountValidation("");
                                } else {
                                  e.target.value = e.target.value.slice(
                                    0,
                                    e.target.value.length - 1
                                  );
                                  setAmountValidation(
                                    "Only numbers are allowed"
                                  );
                                }
                              }}
                            />
                            <p className="text-center text-danger">
                              {amountValidation}
                            </p>
                          </li>
                          <li class=" nav-item" role="presentation">
                            <button
                              class="nav-link active bitcoin ms-0"
                              id="nav-bitcoin-tab"
                              data-bs-toggle="tab"
                              data-bs-target="#nav-bitcoin"
                              type="button"
                              aria-selected="true"
                              role="tab"
                              onClick={() => {
                                setAmount(10);
                              }}
                            >
                              Min
                            </button>
                          </li>

                          <li class="nav-item" role="presentation">
                            <button
                              class="nav-link etherum"
                              id="nav-etherum-tab"
                              data-bs-toggle="tab"
                              data-bs-target="#nav-etherum"
                              type="button"
                              aria-selected="false"
                              tabindex="-1"
                              role="tab"
                              onClick={() => {
                                if (walletType == "gameWallet") {
                                  if (
                                    amount + 10 <=
                                    dashboardData?.availableBalance
                                  ) {
                                    setAmount(amount + 10);
                                  } else {
                                    toast.error("Insufficient balance", {
                                      position: "top-right",
                                      autoClose: 900,
                                    });
                                  }
                                } else {
                                  if (amount + 10 <= balanceKMT) {
                                    setAmount(amount + 10);
                                  } else {
                                    toast.error("Insufficient balance", {
                                      position: "top-right",
                                      autoClose: 900,
                                    });
                                  }
                                }
                              }}
                            >
                              10+
                            </button>
                          </li>

                          <li class="nav-item" role="presentation">
                            <button
                              class="nav-link dash"
                              id="nav-dash-tab"
                              data-bs-toggle="tab"
                              data-bs-target="#nav-dash"
                              type="button"
                              aria-selected="false"
                              tabindex="-1"
                              role="tab"
                              onClick={() => {
                                if (walletType == "gameWallet") {
                                  if (
                                    amount + 100 <=
                                    dashboardData?.availableBalance
                                  ) {
                                    setAmount(amount + 100);
                                  } else {
                                    toast.error("Insufficient balance", {
                                      position: "top-right",
                                      autoClose: 900,
                                    });
                                  }
                                } else {
                                  if (amount + 100 <= balanceKMT) {
                                    setAmount(amount + 100);
                                  } else {
                                    toast.error("Insufficient balance", {
                                      position: "top-right",
                                      autoClose: 900,
                                    });
                                  }
                                }
                              }}
                            >
                              100+
                            </button>
                          </li>

                          <li class="nav-item" role="presentation">
                            <button
                              class="nav-link dash"
                              id="nav-dash-tab"
                              data-bs-toggle="tab"
                              data-bs-target="#nav-dash"
                              type="button"
                              aria-selected="false"
                              tabindex="-1"
                              role="tab"
                              onClick={() => {
                                if (walletType == "gameWallet") {
                                  if (
                                    amount + 500 <=
                                    dashboardData?.availableBalance
                                  ) {
                                    setAmount(amount + 500);
                                  } else {
                                    toast.error("Insufficient balance", {
                                      position: "top-right",
                                      autoClose: 900,
                                    });
                                  }
                                } else {
                                  if (amount + 500 <= balanceKMT) {
                                    setAmount(amount + 500);
                                  } else {
                                    toast.error("Insufficient balance", {
                                      position: "top-right",
                                      autoClose: 900,
                                    });
                                  }
                                }
                              }}
                            >
                              500+
                            </button>
                          </li>

                          <li class="nav-item" role="presentation">
                            <button
                              class="nav-link dash"
                              id="nav-dash-tab"
                              data-bs-toggle="tab"
                              data-bs-target="#nav-dash"
                              type="button"
                              aria-selected="false"
                              tabindex="-1"
                              role="tab"
                              onClick={() => {
                                if (walletType == "gameWallet") {
                                  if (
                                    amount + 1000 <=
                                    dashboardData?.availableBalance
                                  ) {
                                    setAmount(amount + 1000);
                                  } else {
                                    toast.error("Insufficient balance", {
                                      position: "top-right",
                                      autoClose: 900,
                                    });
                                  }
                                } else {
                                  if (amount + 1000 <= balanceKMT) {
                                    setAmount(amount + 1000);
                                  } else {
                                    toast.error("Insufficient balance", {
                                      position: "top-right",
                                      autoClose: 900,
                                    });
                                  }
                                }
                              }}
                            >
                              1000+
                            </button>
                          </li>

                          <li class="nav-item" role="presentation">
                            <button
                              class="nav-link litcoin"
                              id="nav-litcoin-tab"
                              data-bs-toggle="tab"
                              data-bs-target="#nav-litcoin"
                              type="button"
                              aria-selected="false"
                              tabindex="-1"
                              role="tab"
                              onClick={() => {
                                if (walletType == "gameWallet") {
                                  setAmount(dashboardData?.availableBalance);
                                } else {
                                  setAmount(balanceKMT);
                                }
                              }}
                            >
                              Max
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-8">
                    <div class="coin-warpper d-flex align-items-center justify-content-between flex-wrap">
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="wallet"
                          id="gameWallet"
                          onClick={() => {
                            if (amount > dashboardData?.availableBalance) {
                              toast.error("Insufficient balance", {
                                position: "top-right",
                                autoClose: 900,
                              });

                              setAmount(10);
                            }
                            setWalletType("gameWallet");
                          }}
                          defaultChecked
                        />
                        <label class="form-check-label" htmlFor="gameWallet">
                          Game Wallet
                        </label>
                      </div>

                      {sessionStorage.getItem("KMT_Token") ? (
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="radio"
                            name="wallet"
                            id="kmtWallet"
                            onClick={() => {
                              if (amount > balanceKMT) {
                                toast.error("Insufficient balance", {
                                  position: "top-right",
                                  autoClose: 900,
                                });
                                setAmount(10);
                              }
                              setWalletType("kmtWallet");
                            }}
                          />
                          <label class="form-check-label" htmlFor="kmtWallet">
                            KMT Meta Wallet
                          </label>
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <div className="col-md-4">
                    {gameObj?.isGameOver ? (
                      <>
                        <button
                          className="btn btn-block btn-success"
                          onClick={createGame}
                        >
                          Place Game
                        </button>
                      </>
                    ) : null}

                    {!gameObj?.isGameOver &&
                      !gameObj?.roll1 &&
                      !gameObj?.roll2 &&
                      !gameObj?.roll3 &&
                      !gameObj?.roll4 &&
                      getUserIDFromToken() == gameObj?.player1?._id ? (
                      <>
                        <button
                          className="btn btn-block btn-danger"
                          onClick={(e) => {
                            e.preventDefault();
                            deleteGame();
                          }}
                        >
                          Delete Game
                        </button>
                      </>
                    ) : null}

                    {!gameObj?.isGameOver &&
                      !gameObj?.roll1 &&
                      !gameObj?.roll2 &&
                      !gameObj?.roll3 &&
                      !gameObj?.roll4 &&
                      getUserIDFromToken() != gameObj?.player1?._id ? (
                      <>
                        <button
                          className="btn btn-block btn-danger"
                          onClick={(e) => {
                            e.preventDefault();
                            dropGame();
                          }}
                        >
                          Drop Game
                        </button>
                      </>
                    ) : null}

                    {!gameObj?.isGameOver && gameObj?.roll1 ? (
                      <>
                        <button className="btn btn-block btn-success " disabled>
                          Playing
                        </button>
                      </>
                    ) : null}

                    <select
                      className="form-control text-light my-2"
                      onChange={(e) => {
                        setTotalplayers(e.target.value);
                      }}
                    >
                      <option
                        value="0"
                        selected={totalPlayers == 0 ? true : false}
                      >
                        Number of Player
                      </option>
                      <option
                        value="2"
                        selected={totalPlayers == 2 ? true : false}
                      >
                        Two Players
                      </option>
                      <option
                        value="3"
                        selected={totalPlayers == 3 ? true : false}
                      >
                        Three Players
                      </option>
                      <option
                        value="4"
                        selected={totalPlayers == 4 ? true : false}
                      >
                        Four Players
                      </option>
                    </select>
                  </div>

                  <div class="col-lg-12 mt-2">
                    <div class="card transaction-table">
                      <div class="card-header border-0 flex-wrap pb-0">
                        <div class="mb-2">
                          <h4 class="card-title">Game Record</h4>
                        </div>
                      </div>
                      <div class="card-body p-0">
                        <div class="table-responsive">
                          <table class="table  text-white table-striped tr-rounded order-tbl">
                            <thead>
                              <tr>
                                <th>S.No</th>
                                <th>Game Id</th>
                                <th>No. of Player</th>
                                <th>Amount</th>
                                <th>Winning Amount</th>
                                <th>Date</th>
                                <th>Status</th>
                              </tr>
                            </thead>
                            {!loadingTable ? (
                              <tbody>
                                {gameRecord.map((elem, index) => {
                                  if (index > 4) {
                                    return;
                                  }
                                  return (
                                    <tr>
                                      <td>{index + 1}</td>
                                      <td>{elem?.gameId}</td>
                                      <td>{4}</td>
                                      <td className="text-danger">
                                        {elem?.amount} INR
                                      </td>

                                      <td>
                                        {getUserIDFromToken() ==
                                          elem?.winner ? (
                                          <p className="text-success">
                                            {elem?.amount * 4} INR
                                          </p>
                                        ) : (
                                          <p className="text-success">00 INR</p>
                                        )}
                                      </td>

                                      <td>
                                        {humanReadableTimeAndDate(
                                          elem?.createdAt
                                        )}
                                      </td>
                                      <td>
                                        {getUserIDFromToken() ==
                                          elem?.winner ? (
                                          <p className="text-success">Winner</p>
                                        ) : (
                                          <p className="text-danger">Looser</p>
                                        )}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            ) : null}

                            {loadingTable ? (
                              <TablePlaceholder colNum={6} />
                            ) : null}
                          </table>
                          {gameRecord.length == 0 ? (
                            <p className="text-center">No data to show</p>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              </div>
            </div>

            <div class="col-lg-3">
              <div class="card">
                {/* <div class="diceWrap">
                  <div class="dice rolling">
                    <div class="diceFace front"></div>
                    <div class="diceFace up"></div>
                    <div class="diceFace left"></div>
                    <div class="diceFace right"></div>
                    <div class="diceFace bottom"></div>
                    <div class="diceFace back"></div>
                  </div>
                </div> */}

                <div class="card-header">
                  {" "}
                  <h4 class="card-title">Recent Winners</h4>
                </div>

                <div class="card-body py-2 px-3 table-responsive">
                  <table className="table text-center text-white table-striped tr-rounded order-tbl">
                    <tbody>
                      {dummyData?.map((elem, index) => {
                        if (elem?.userId == getUserIDFromToken()) {
                          return (
                            <tr key={index}>
                              <td className="text-start bg-success ">
                                {elem?.name}
                              </td>
                              <td className="text-end bg-success">
                                {elem?.amount} INR
                              </td>
                            </tr>
                          );
                        }

                        return (
                          <tr key={index}>
                            <td className="text-start">{elem?.name}</td>
                            <td className="text-success">{elem.amount} INR</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="modal fade BlurModal"
        id="ForInvite"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <form>
              <div class="modal-header px-3 flex-wrap d-flex justify-content-between">
                <h1 class="modal-title fs-5" id="exampleModalLabel">
                  Invitation
                </h1>

                <ul class="nav nav-pills light" id="myTab4" role="tablist">
                  <li class="nav-item" role="presentation">
                    <button
                      class="nav-link active"
                      id="home-tab4"
                      data-bs-toggle="tab"
                      data-bs-target="#WithBadges"
                      type="button"
                      role="tab"
                      aria-selected="true"
                      onClick={() => {
                        fetchOnlineMembers();
                      }}
                    >
                      Friends
                    </button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button
                      class="nav-link"
                      id="profile-tab4"
                      data-bs-toggle="tab"
                      data-bs-target="#WithBadges-html"
                      type="button"
                      role="tab"
                      aria-selected="false"
                      tabindex="-1"
                      onClick={() => {
                        fetchOnlinePlayers();
                      }}
                    >
                      Players
                    </button>
                  </li>
                </ul>
              </div>
              <div class="modal-body py-3 px-3">
                <div class="tab-content" id="myTabContent4">
                  <div
                    class="tab-pane fade active show"
                    id="WithBadges"
                    role="tabpanel"
                    aria-labelledby="home-tab4"
                  >
                    <div class="">
                      <div class="basic-list-group">
                        <ul class="list-group">
                          <input
                            type="text"
                            className="form-control my-3"
                            placeholder="Enter user ID"
                            onChange={searchOnlineMembersbyId}
                          />
                          {filteredFriends?.map((elem, index) => {
                            return (
                              <li class="list-group-item d-flex justify-content-between align-items-center">
                                <span>{elem?.name}</span>

                                <span>{elem?.userId}</span>

                                <span
                                  class="badge badge-sm badge-primary badge-pill"
                                  onClick={() => {
                                    inviteToPlay(elem?.userId);
                                  }}
                                >
                                  Invite
                                </span>
                              </li>
                            );
                          })}
                        </ul>

                        {!onlineMembers.length ? (
                          <>
                            <img
                              src="../images/noplayer.png"
                              className="nouser"
                              alt=""
                            />
                            <p className="text-light mb-0 text-center">
                              No Friends is online
                            </p>
                          </>
                        ) : null}
                      </div>
                    </div>
                  </div>

                  <div
                    class="tab-pane fade"
                    id="WithBadges-html"
                    role="tabpanel"
                    aria-labelledby="home-tab4"
                  >
                    <div class=" ">
                      <div class="basic-list-group">
                        <ul class="list-group">
                          <input
                            type="text"
                            className="form-control my-3"
                            placeholder="Enter user ID"
                            onChange={searchOnlinePlayersbyId}
                          />
                          {filteredPlayers?.map((elem, index) => {
                            return (
                              <li class="list-group-item d-flex justify-content-between align-items-center">
                                <span>{elem?.name}</span>

                                <span>{elem?.userId}</span>

                                <span
                                  class="badge badge-sm badge-primary badge-pill"
                                  onClick={() => {
                                    inviteToPlay(elem?.userId);
                                  }}
                                >
                                  Invite
                                </span>
                              </li>
                            );
                          })}
                        </ul>
                        {!onlinePlayers.length ? (
                          <>
                            <img
                              src="../images/noplayer.png"
                              className="nouser"
                              alt=""
                            />
                            <p className="text-light mb-0 text-center">
                              No Players is online
                            </p>
                          </>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer px-3">
                <button
                  type="button"
                  class="btn btn-danger btn-sm light"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div
        class="modal fade BlurModal"
        id="Result"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-body py-0 px-0">
              <img src="../images/result.jpg" className="img-fluid" alt="" />
              <div className="winner">
                <h1 className="text-light text-center"></h1>
                <h3 className="text-center text-light"></h3>
                <h6>Result</h6>
                <h2 className="text-success fw-600">
                  {" "}
                  WINNER <br />
                  {gameObj?.winner?.name}
                </h2>
                <h2>
                  {" "}
                  Congratulations {gameObj?.winner?.name} you have won{" "}
                  <span className="text-success fw-600">
                    {" "}
                    {(gameObj?.amount * 4).toFixed(2)} INR
                  </span>
                </h2>
              </div>
            </div>
          </div>
          <button
            type="button"
            class="btn btn-secondary d-none"
            id="close-winner-banner"
            data-bs-dismiss="modal"
          >
            Close
          </button>
        </div>
      </div>
    </>
  );
};

export default Dice;
