import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { socket } from "../../../../../Helper/Variables";
import { toast } from "react-toastify";
import { formatDate } from "../../helper/function/Helper.function";
import UserFlycrickTimeDifference from "../user-component/User.Time.Difference";
import UserFlycrickFlightCardButtons from "../user-component/User.Flight.Card.Buttons";
import UserFlycrickFlightCardPlaceholder from "../user-component/User.Flight.Card.Placeholder";
import UserFlycrickBookedTicketCount from "../user-component/User.Booked.Ticket.Count";
import token from "../../../../../Helper/Token";
export default function UserFlycrickSelectPlane() {
  const [bookedTicketsCount, setBookedTicketsCount] = useState([]);
  const [loading, setLoading] = useState({ cardLoading: false});
  const [allFlights, setAllFlights] = useState([]);
  const [headerData, setHeaderData] = useState({
    availableBalance: (
      <span class="card-text placeholder-glow">
        <span class="placeholder col-10"></span>
      </span>
    ),
    totalBets: (
      <span class="card-text placeholder-glow">
        <span class="placeholder col-10"></span>
      </span>
    ),
    totalWiningAmount: (
      <span class="card-text placeholder-glow">
        <span class="placeholder col-10"></span>
      </span>
    ),
  });

  useEffect(() => {
    socket.on("User_Flycrick_Flight_Booked", (data) => {
      setBookedTicketsCount(data);
      return () => {
        socket.off("User_Flycrick_Flight_Booked");
      };
    });
  }, []);

  useEffect(() => {
    fetchAllFlights();
    getFlycrickHeaderData();
  }, []);

  async function getFlycrickHeaderData() {
    token.get('/random-flight/get-header-data').then((res)=>{
      if (res.data?.status === "success") {
        setHeaderData(res.data.data);
      } else {
        toast.error(res.data.message);
      }
    }).catch ((error)=>{ toast.error("Connection issue");})
      
  }

  async function fetchAllFlights() {
    setLoading((prev) => ({ ...prev, cardLoading: true }));
    token.get('/random-flight/all-flights').then((res)=>{
      if (res.data.status === "success") {
        setAllFlights(res.data.data);
      } else {
        toast.error(res.data.message);
      }
      setLoading((prev) => ({ ...prev, cardLoading: false }));
    }).catch ((error)=>{ 
      toast.error("Connection issue");
      setLoading((prev) => ({ ...prev, cardLoading: false }));
    })
  }

  return (
    <>
      <>
        <button
          onClick={() => {
            document.getElementById("open-public-modal").click();
          }}
          hidden
        >
          OPEN
        </button>
      </>

      <div class="page-titles"><div class="container-fluid"><div class="sub-dz-head"><div class="d-flex align-items-center dz-head-title"><h2 class="text-white m-0"></h2></div></div></div></div>

      <div class="content-body">
        <div class="container-fluid ">
          <div class="row">
            <div class="col-xl-12 col-xxl-12">
              <div class="row quizee">
                <>
                  <div class="col-xl-3 col-sm-6 col-6 ">
                    <a href="/admin/all-users-report">
                      <div class="card banking-card pull-up pull-up">
                        <div class="card-header border-0 flex-wrap pb-0">
                          <div class="revenue-date text-light">
                            <span>Available Balance</span>
                            <h4>
                              {" "}
                              ₹
                              {headerData?.availableBalance < 10
                                ? "0" +
                                  Number(headerData?.availableBalance)?.toFixed(
                                    2
                                  )
                                : Number(headerData?.availableBalance)?.toFixed(
                                    2
                                  )}
                            </h4>
                          </div>
                          <div class="setting bg-primary">
                            <i class="bi bi-people text-light fs-18"></i>
                          </div>
                        </div>
                        <div class="card-body pb-0 custome-tooltip d-flex align-items-center"></div>
                      </div>
                    </a>
                  </div>

                  <div class="col-xl-3 col-sm-6 col-6">
                    <a href="/admin/fund-request-report/1">
                      <div class="card banking-card pull-up pull-up">
                        <div class="card-header border-0 flex-wrap pb-0">
                          <div class="revenue-date text-light">
                            <span> Total Booking</span>
                            <h4>
                              {headerData?.totalBets < 10
                                ? "0" + headerData?.totalBets
                                : headerData?.totalBets}
                            </h4>
                          </div>
                          <div class="setting bg-success">
                            <i class="bi bi-credit-card text-light fs-18"></i>
                          </div>
                        </div>
                        <div class="card-body pb-0 custome-tooltip d-flex align-items-center"></div>
                      </div>
                    </a>
                  </div>

                  <div class="col-xl-3 col-sm-6 col-12">
                    <a href="/admin/all-users-report">
                      <div class="card banking-card pull-up">
                        <div class="card-header border-0 flex-wrap pb-0">
                          <div class="revenue-date text-light">
                            <span>Total Winnings</span>
                            <h4>
                              {" "}
                              ₹
                              {headerData?.totalWiningAmount < 10
                                ? "0" +
                                  Number(
                                    headerData?.totalWiningAmount
                                  )?.toFixed(2)
                                : Number(
                                    headerData?.totalWiningAmount
                                  )?.toFixed(2)}
                            </h4>
                          </div>
                          <div class="setting bg-warning">
                            <i class="bi bi-people text-light fs-18"></i>
                          </div>
                        </div>
                        <div class="card-body pb-0 custome-tooltip d-flex align-items-center"></div>
                      </div>
                    </a>
                  </div>
                  <div class="col-xl-3 col-sm-6 col-12">
                    <a href="/admin/all-users-report">
                      <div class="card banking-card pull-up">
                        <div class="card-header border-0 flex-wrap pb-0">
                          <div class="revenue-date text-light">
                            <span>Today's Winnings</span>
                            <h4>
                              {" "}
                              ₹
                              {headerData?.todayWinning < 10
                                ? "0" +
                                  Number(headerData?.todayWinning)?.toFixed(2)
                                : Number(headerData?.todayWinning)?.toFixed(2)}
                            </h4>
                          </div>
                          <div class="setting bg-warning">
                            <i class="bi bi-people text-light fs-18"></i>
                          </div>
                        </div>
                        <div class="card-body pb-0 custome-tooltip d-flex align-items-center"></div>
                      </div>
                    </a>
                  </div>
                </>

                {loading?.cardLoading ? (
                  <>
                    <UserFlycrickFlightCardPlaceholder />;
                  </>
                ) : null}

                {!loading?.cardLoading ? (
                  <>
                    {allFlights?.map((elem, index) => {
                      return (
                        <div key={index} class="col-md-4 col-sm-4 col-12">
                          <div class="card card-box pull-up">
                            <div class="card-header   border-0">
                              <h6 class="mb-0">
                                <img
                                  src="/favicon/logo-light.png"
                                  className="planelogo"
                                  alt=""
                                />
                                RDMFLY{" "}
                                <span className="font-w300">
                                  {elem?.flightNumber}
                                </span>{" "}
                              </h6>

                              <UserFlycrickFlightCardButtons
                                departureTime={elem?.departureTime}
                                flightId={elem?._id}
                                betCount={elem?.betCount}
                              />
                            </div>

                            <Link to={`/user/random-flight/live-plane/${elem?._id}`}>
                              <div class="card-body  pt-3 px-3">
                                <div class="row alig">
                                  <div class="col">
                                    <span class="text-light">From</span>
                                    <div class=" border-0 text-light pt-1 fs-16 font-w600">
                                      {elem?.fromPlace}
                                    </div>
                                  </div>
                                  <div class="col text-center">
                                    <span class="text-light">Departure</span>
                                    <div class=" border-0 pt-1">
                                      <span class=" text-warning">
                                        {formatDate(elem?.departureTime)}
                                      </span>
                                    </div>
                                  </div>

                                  <div class="col text-end  ">
                                    <span class="text-light">To</span>
                                    <div class="border-0 text-light pt-1 fs-16 font-w600">
                                      {elem?.finalDestination
                                        ?.destinationNumber ? (
                                        <h6 class="mb-0 bg-success border">
                                          {elem?.finalDestination
                                            ?.destinationNumber < 10
                                            ? "0" +
                                              elem?.finalDestination
                                                ?.destinationNumber
                                            : elem?.finalDestination
                                                ?.destinationNumber >= 10
                                            ? elem?.finalDestination
                                                ?.destinationNumber
                                            : null}
                                        </h6>
                                      ) : null}

                                      {elem?.finalDestination?.destination
                                        ? elem?.finalDestination?.destination
                                        : "----"}
                                    </div>
                                  </div>
                                </div>
                                <UserFlycrickTimeDifference departureTime={elem?.departureTime} />
                                <UserFlycrickBookedTicketCount count={bookedTicketsCount[index]} departureTime={elem?.departureTime} />
                              </div>
                            </Link>
                          </div>
                        </div>
                      );
                    })}
                  </>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
