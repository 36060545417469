import { useEffect, useState, useRef } from "react";
import { toast } from "react-toastify";
import { MDBDataTable } from "mdbreact";
import token from "../../../Helper/Token";
import { Link } from "react-router-dom";
import Swal from 'sweetalert2'
export default function AdminkycReport() {
    const closemodel = useRef(null)
    const image = useRef(null)
    const [data, setData] = useState({ type: 'upi', address: '', qrcode: '', thum: '', password: '' })
    const [action, setAction] = useState({ type: 'upi' })
    const [accounts, setAccounts] = useState([])
    const GetInput = (e) => {
        const target = e.currentTarget;
        if (target.name === 'qrcode') {
            if (target.files && target.files[0]) {
                const reader = new FileReader();
                reader.onload = function (e) {
                    const thum = e.target.result;
                    setData((prev) => ({ ...prev, [target.name]: target.files[0], thum: thum }));
                };
                reader.readAsDataURL(target.files[0]);
            } else {
                setData((prev) => ({ ...prev, [target.name]: '' }));
            }
        } else {
            setData((prev) => ({ ...prev, [target.name]: target.value }));
        }
    }
    const table = {
        columns: [
            {
                label: "S.No",
                field: "index",
                sort: "asc",
                width: 150,
            },
            {
                label: "Status",
                field: "status",
                sort: "asc",
                width: 150,
            },
            {
                label: "Active/Inactive",
                field: "mstatus",
                sort: "asc",
                width: 150,
            },
            {
                label: "Action",
                field: "action",
                sort: "asc",
                width: 150,
            },
        ],
        rows: [],
    };
    if (action.type !== 'bank') {
        table.columns.splice(1, 0,
            {
                label: "Address",
                field: "address",
                sort: "asc",
                width: 150,
            },
            {
                label: "QR Code",
                field: "qrcode",
                sort: "asc",
                width: 150,
            }
        );
    }
    const getdata = () => {
        token.get('/admin/account-data?type=upi').then((res) => {
            if (res.data.status == 1) { setAccounts(res.data.data) }
        }).catch((err) => { })
    }
    const Mamage = (e) => {
        e.preventDefault();
        const formdata = { ...data, thum: null };
        token.post('/admin/add-account-data', formdata, { headers: { 'Content-Type': 'multipart/form-data' } }).then((res) => {
            if (res.data.status === 1) {
                image.current.value = null;
                setData((prev) => ({ ...prev, address: '', qrcode: '', thum: '', password: '' }))
                getdata(); closemodel.current.click()
                toast.success(res.data.message);
            } else {
                toast.warning(res.data.message);
            }
        }).catch((err) => {
            toast.error(err.response.data.message || 'Something went wrong');
        });
    }
    const manageStatus = (e) => {
        const target = e.currentTarget;
        const status = target.checked ? 1 : false;
        const requestId = target.value;
        token.post('/admin/manage-account', { requestId, status }).then((res) => {
            if (res.data.status === 1) {
                getdata();
                toast.success(res.data.message);
            } else {
                toast.warning(res.data.message);
            }
        }).catch((err) => {
            toast.error(err.response.data.message || 'Something went wrong');
        });
    }
    const Delete = (requestId) => {
        Swal.fire({
            title: "Are you sure?",
            text: "you want to delete.",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes, delete it!"
        }).then((result) => {
            if (result.isConfirmed) {
                token.delete(`/admin/account-data?requestId=${requestId}`).then((res) => {
                    if (res.data.status === 1) {
                        getdata(); toast.success(res.data.message);
                    } else {
                        toast.warning(res.data.message);
                    }
                }).catch((err) => {
                    toast.error(err.response.data.message || 'Something went wrong');
                });
            }
        });

    }
    accounts.map((tr, ind) => {
        table.rows.push({
            index: (ind + 1).toString().padStart(2, '0'),
            type: tr.type,
            address: tr.type !== 'bank' ? tr.address : "",
            qrcode: tr.type !== 'bank' ? <img width="70" src={`${process.env.REACT_APP_API_URL}/${tr.qrcode}`} /> : "",
            status: tr.status == 1 ? <span className="text-info">Active</span> : tr.status == 0 ? <span className="text-warning">Inactive</span> : <span className="text-danger">Block</span>,
            mstatus: <div class="form-check form-switch"><input class="form-check-input" type="checkbox" role="switch" value={tr.requestId} checked={tr.status == 1 ? true : false} onChange={manageStatus} /></div>,
            action: <Link className="text-danger" onClick={() => Delete(tr._id)}><i class="bi bi-trash fs-4"></i></Link>
        })
    })
    useEffect(() => { image.current.value = null; setData((prev) => ({ ...prev, address: '', qrcode: '', thum: '', password: '' })); getdata() }, [])
    return (
        <>
            <div class="page-titles d-flex justify-content-between">
                <h1 className="text-light">Account</h1>
                <button class="btn btn-success" data-bs-toggle="modal" data-bs-target="#managemodel">Add Upi</button>
            </div>
            <div class="content-body">
                <div class="container-fluid">
                    <div class="row ">
                        <div class="col-lg-12">
                            <div class="card">
                                <div class="card-header">UPI Address</div>
                                <div class="card-body">
                                    <MDBDataTable bordered small data={table} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade" id="managemodel" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <form class="modal-dialog" onSubmit={Mamage}>
                    <div class="modal-content">
                        <div class="modal-header">
                            <h1 class="modal-title fs-5" id="staticBackdropLabel">Account</h1>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div class="mb-4" hidden>
                                <label for="acctypetype text-capitalize">Type</label>
                                <select class="form-select  form-control-sm" name="type" id="acctypetype" aria-label="Default select example" onChange={GetInput} required>
                                    <option value="" selected={data.type == '' ? true : false}>Select</option>
                                    <option value="upi" selected={data.type == 'upi' ? true : false}>UPI</option>
                                    <option value="tron" selected={data.type == 'tron' ? true : false}>TRON</option>
                                    <option value="usdt" selected={data.type == 'usdt' ? true : false}>USDT</option>x
                                </select>
                            </div>
                            <div class="mb-4">
                                <label class="form-label text-light text-capitalize" for="kmt">{data.type} Address</label>
                                <input type="text" class="form-control form-control-sm" placeholder={`${data.type} address`} id="kmt" name="address" value={data.address} onChange={GetInput} required />
                            </div>
                            <div class="mt-4">
                                <label class="form-label text-light text-capitalize" for="upi-code-pic">{data.type} QR Code</label>
                                <input type="file" accept="image/*" class="form-control form-control-sm" ref={image} name="qrcode" id="upi-code-pic" onChange={GetInput} required />
                            </div>
                            <div class="mt-4 text-center">
                                <img className="m-0" width="200" style={{ display: "inline-block" }} src={data.thum ? data.thum : ""} class="qr-image m-5" alt={`${data.type} QR CODE`} />
                            </div>
                            <div class="position-relative mt-4">
                                <label for="transactionPassword text-capitalize" class="form-label text-light required">Transaction Password</label>
                                <input type="password" id="transactionPassword" class="form-control text-light" name="password" placeholder="Enter your transaction password" value={data.password} onChange={GetInput} required />
                                <span class=" eye"><i class="fa fa-eye"></i></span>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" ref={closemodel}>Close</button>
                            <button type="submit" class="btn btn-primary">Submit</button>
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
}
