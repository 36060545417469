import Login from "./App/Pages/Login";
import Sigup from "./App/Pages/Signup";
import Header from "./User/Components/Header";
import Dashbord from "./User/Pages/Account/Dashbord";
import Dice from "./User/Pages/Account/DiceGame";
import EditProfile from "./User/Pages/Account/EditProfile";
import Myprofile from "./User/Pages/Account/profile";
import ChangePassword from "./User/Pages/Account/ChangePassword";
import TransactionPassword from "./User/Pages/Account/TransactionPassword";
import Kyc from "./User/Pages/Account/KYC";
import Package from "./User/Pages/Account/Package";
import AllTeamReport from "./User/Pages/MyNetwork/MyTeam";
import DirectTeamReport from "./User/Pages/MyNetwork/DirectTeam";
import DownlineMember from "./User/Pages/MyNetwork/DownlineTeam";
import Mypool1 from "./User/Pages/MyNetwork/pool1";
import Mypool2 from "./User/Pages/MyNetwork/pool2";
import Mypool3 from "./User/Pages/MyNetwork/pool3";
import Mypool4 from "./User/Pages/MyNetwork/pool4";
import Mypool5 from "./User/Pages/MyNetwork/pool5";
import Mypool6 from "./User/Pages/MyNetwork/pool6";
import PoolList from "./User/Pages/MyNetwork/PoolList";
import ReferralIncomeReport from "./User/Pages/EarningDetails/ReferralIncome";
import BinaryIncome from "./User/Pages/EarningDetails/BinaryIncome";
import Repurchaseincome from "./User/Pages/EarningDetails/Repurchase";
import CarFund from "./User/Pages/EarningDetails/CarFund";
import HouseFund from "./User/Pages/EarningDetails/HouseFund";
import CptoBonous from "./User/Pages/EarningDetails/CptoBonous";
import GeneologyTree from "./User/Pages/MyNetwork/GeneologyTree";
import Pool1 from "./User/Pages/EarningDetails/Pool1";
import Pool2 from "./User/Pages/EarningDetails/Pool2";
import Pool3 from "./User/Pages/EarningDetails/Pool3";
import Pool4 from "./User/Pages/EarningDetails/Pool4";
import Pool5 from "./User/Pages/EarningDetails/Pool5";
import Pool6 from "./User/Pages/EarningDetails/Pool6";
import RechargeIncome from "./User/Pages/EarningDetails/Recharge";
import TotelIncome from "./User/Pages/EarningDetails/Totalincome";
import Repurchasewith from "./User/Pages/EarningDetails/Repurchasewith";
import Rewardincome from "./User/Pages/EarningDetails/Rewards";
import RoyaltyIncome from "./User/Pages/EarningDetails/RoyaltyIncome";
import UsePin from "./User/Pages/Pin/UsedPin";
import PinReceived from "./User/Pages/Pin/PinReceived";
import PinRequestRepoer from "./User/Pages/Pin/PinRequestReport";
import PinTransfer from "./User/Pages/Pin/PinTransfer";
import PinTransferReport from "./User/Pages/Pin/PinTransferReport";
import UnusedPin from "./User/Pages/Pin/UnusedPin";
import PinRequest from "./User/Pages/Pin/PinRequest";
import Support from "./User/Pages/Support/Support";
import VendorsList from "./User/Pages/Vendors/VendorsList";
import RechargeReport from "./User/Pages/Recharge/RechargeReport";
import UsedWallet from "./User/Pages/Wallet/UsedWallet";
import UnuseWallet from "./User/Pages/Wallet/UnuseWallet";
import AddPopup from "./User/Pages/PopUp/AddPopup";
import AddBusiness from "./User/Pages/PopUp/AddBusiness";
import WalletReceiveReport from "./User/Pages/PurchaseProduct/WalletReceiveReport";
import WalletTransferReport from "./User/Pages/PurchaseProduct/WalletTransferReport";
import OfflinePurchase from "./User/Pages/PurchaseProduct/OfflinePurchase";
import OrderDetails from "./User/Pages/PurchaseProduct/OrderDetails";
import WalletTransferReq from "./User/Pages/PurchaseProduct/WalletTransferReq";
import Error404 from "./User/Components/Error404";
import DiceRequest from "./User/Pages/Reports/DiceRequest";
import FundRequest from "./User/Pages/Requests/FundRequest";
import FundTransfer from "./User/Pages/Requests/FundTransfer";
import FundRequesReport from "./User/Pages/Reports/FundRequestReport";
import FundTransferReport from "./User/Pages/Reports/FundTransferReport";
import FundRevieveReport from "./User/Pages/Reports/FundRecieveReport";
import GameReport from "./User/Pages/Reports/GameReport";
import WithdrawelRequest from "./User/Pages/Requests/WithdrawelRequest";
import PrivacyPolicy from "./User/Pages/Documents/PrivacyPolicy";
import TermsAndConditions from "./User/Pages/Documents/T&C";
import WithdrawalReport from "./User/Pages/Reports/WithdrawalReport";
import AdminDashbord from "./User/Pages/Account/AdminDashboard";
import AllUserReport from "./User/Pages/Admin/Reports/AllUserReport";
import SingleUserReport from "./User/Pages/Admin/Reports/SingleUserReport";
import WithdrawalReportAdmin from "./User/Pages/Admin/Reports/WithdrawalReport";
import AllUserReportByStatus from "./User/Pages/Admin/Reports/AllBlockedUserReport";
import FundRequesReportAdmin from "./User/Pages/Admin/Reports/FundRequestReport";
import UpdateKYCAdmin from "./User/Pages/Admin/Requests/UpdateKYC";
import GameReportAdmin from "./User/Pages/Admin/Reports/AllDiceGameReport";
import DirectTeamReportUser from "./User/Pages/Reports/DirectTeamReport";
import AdminkycReport from "./User/Pages/Admin/Reports/kycReport";
import NewReportAdmin from "./User/Pages/Admin/Reports/NewsReport";
import NewForm from "./User/Pages/Admin/Requests/NewsForm";
import AddQuiz from "./User/Pages/Admin/Quiz Game/AddQuiz";
import AllQuizQuestionReport from "./User/Pages/Admin/Quiz Game/QuizList";
import PlayQuiz from "./User/Pages/Quiz/PlayQuiz";
import UserQuiz from "./User/Pages/Quiz/QuizHome";
import AllQuizCategoryReport from "./User/Pages/Admin/Quiz Game/CategoryList";
import QuizDifficultyLevel from "./User/Pages/Quiz/QuizDifficulty";
import AviatorGame from "./User/Pages/Aviator/AvatorGame";
import HomePage from "./User/Pages/HomePage/HomePage";
import HomePagePostLogin from "./User/Pages/HomePage/Home-Page-Post-Login";
import AviatorLiveGameReport from "./User/Pages/Aviator/Aviator.Live.Report";
import Spin from "./User/Pages/SpinWheel/Spin";

import FlightSelect from "./User/Pages/flight/user-module/user-page/User.Select.Plane.Page";
import LiveFlight from "./User/Pages/flight/user-module/user-page/User.Live.Plane.Page";
import BetPlane from "./User/Pages/flight/user-module/user-page/User.Bet.Plane.Page";
import FlightReport from "./User/Pages/flight/user-module/user-page/User.Ticket.Report.Page";

import AdminFlightTicketsReport from "./User/Pages/flight/admin-module/flycrick-admin-page/Admin.Ticket.Report";
import AdminFlightReport from "./User/Pages/flight/admin-module/flycrick-admin-page/Admin.Flight.Report";
import AdminFlightDetails from "./User/Pages/flight/admin-module/flycrick-admin-page/Admin.Force.Land.Flight";
import AdminFlightLandReport from "./User/Pages/flight/admin-module/flycrick-admin-page/Admin.Flight.Land.Report";
import AdminFlightLandDetails from "./User/Pages/flight/admin-module/flycrick-admin-page/Admin.Flight.Leaning.Details";

import SelfAccount from "./User/Pages/Account/SelfAccount";
const Routes = [
  {
    path: "/",
    element: <HomePage />,
  },
  {
    path: "/home-page",
    element: <HomePagePostLogin />,
  },
  {
    path: "/user/register/:id",
    element: <Sigup />,
  },
  {
    path: "sign-up",
    element: <Sigup />,
  },
  {
    path: "login",
    element: <Login />,
  },
  {
    path: "*",
    element: <Error404 />,
  },
  {
    path: "/fund",
    element: <Header />,
    children: [
      { path: "fund-request", element: <FundRequest /> },
      { path: "fund-transfer", element: <FundTransfer /> },
      { path: "withdrawel-request", element: <WithdrawelRequest /> },
      { path: "withdrawel-report", element: <WithdrawalReport /> },
      { path: "fund-request-report", element: <FundRequesReport /> },
      { path: "fund-transfer-report", element: <FundTransferReport /> },
      { path: "fund-recieve-report", element: <FundRevieveReport /> },
    ],
  },
  {
    path: "/dice",
    element: <Header />, 
    children: [
      { path: "game-report", element: <GameReport /> }
    ],
  },
  {
    path: "/doc",
    element: <Header />,
    children: [
      { path: "privacy-policy", element: <PrivacyPolicy /> },
      { path: "t&c", element: <TermsAndConditions /> },
    ],
  },
  {
    path: "/admin",
    element: <Header />,
    children: [
      {
        path: "admin-dashboard",
        element: <AdminDashbord />,
      },
      {
        path: "all-users-report",
        element: <AllUserReport />,
      },
      {
        path: "all-users-report/:id",
        element: <AllUserReportByStatus />,
      },
      {
        path: "single-user-report/:id",
        element: <SingleUserReport />,
      },
      {
        path: "withdrawal-report/:status/:payment_mode",
        element: <WithdrawalReportAdmin />,
      },
      {
        path: "fund-request-report/:status/:payment_mode",
        element: <FundRequesReportAdmin />,
      },
      {
        path: "upi-details",
        element: <SelfAccount />,
      },
      {
        path: "usdtandtrx",
        element: <UpdateKYCAdmin  />,
      },
      {
        path: "game-record",
        element: <GameReportAdmin />,
      },
      {
        path: "aviator-live-game-report",
        element: <AviatorLiveGameReport />,
      },
      {
        path: "add-quiz",
        element: <AddQuiz />,
      },
      {
        path: "edit-quiz/:id",
        element: <AddQuiz />,
      },
      {
        path: "all-quiz-questions",
        element: <AllQuizQuestionReport />,
      },
      {
        path: "all-quiz-categories",
        element: <AllQuizCategoryReport />,
      },
      {
        path: "admin-kyc-report/:id",
        element: <AdminkycReport />,
      },
      {
        path: "news-report",
        element: <NewReportAdmin />,
      },
      {
        path: "news-form/:id",
        element: <NewForm />,
      },
      {
        path: "random-flight/ticket-report",
        element: <AdminFlightTicketsReport />,
      },
      {
        path: "random-flight/flight-report",
        element: <AdminFlightReport />,
      },
      {
        path: "random-flight/flight-report",
        element: <AdminFlightReport />,
      },
      {
        path: "random-flight/flight-report/:id",
        element: <AdminFlightDetails />,
      },
      {
        path: "random-flight/flight-landing-report",
        element: <AdminFlightLandReport />,
      },
      {
        path: "random-flight/flight-land-report/:flightdate",
        element: <AdminFlightLandDetails />,
      },
      
    ],
  },
  {
    path: "/user",
    element: <Header />,
    children: [
      {
        path: "dashboard",
        element: <Dashbord />,
      },
      {
        path: "DiceGame",
        element: <Dice />,
      },
      {
        path: "DiceRequest",
        element: <DiceRequest />,
      },
      {
        path: "edit-profile",
        element: <EditProfile />,
      },
      {
        path: "play-quiz/:quizCategory/:questionId/:quizLevel",
        element: <PlayQuiz />,
      },
      {
        path: "quiz-home",
        element: <UserQuiz />,
      },
      {
        path: "dfficuly-level/:quizCategory",
        element: <QuizDifficultyLevel />,
      },
      {
        path: "aviator",
        element: <AviatorGame />,
      },
      {
        path: "random-flight",
        element: <FlightSelect />,
      },
      {
        path: "random-flight/live-plane/:flightId",
        element: <LiveFlight />,
      },
      {
        path: "random-flight/bet-plane/:flightId",
        element: <BetPlane />,
      },
      {
        path: "random-flight/report",
        element: <FlightReport />,
      },
      {
        path: "changpwd",
        element: <ChangePassword />,
      },
      {
        path: "transpwd",
        element: <TransactionPassword />,
      },
      {
        path: "mypackage",
        element: <Package />,
      },
      {
        path: "Kyc",
        element: <Kyc />,
      },
      {
        path: "network/team",
        element: <AllTeamReport />,
      },
      {
        path: "network/direct-team",
        element: <DirectTeamReportUser />,
      },
      {
        path: "network/pool1",
        element: <Mypool1 />,
      },
      {
        path: "network/pool2",
        element: <Mypool2 />,
      },
      {
        path: "network/pool3",
        element: <Mypool3 />,
      },
      {
        path: "network/pool4",
        element: <Mypool4 />,
      },
      {
        path: "network/pool5",
        element: <Mypool5 />,
      },
      {
        path: "network/pool6",
        element: <Mypool6 />,
      },
      {
        path: "network/poollist",
        element: <PoolList />,
      },
      {
        path: "network/matching",
        element: <GeneologyTree />,
      },
      {
        path: "network/levelwisedownline",
        element: <DownlineMember />,
      },
      {
        path: "network/profile",
        element: <Myprofile />,
      },
      {
        path: "earning/referralincome",
        element: <ReferralIncomeReport />,
      },
      {
        path: "earning/binaryincome",
        element: <BinaryIncome />,
      },
      {
        path: "earning/repurchaseincome",
        element: <Repurchaseincome />,
      },
      {
        path: "earning/carfundincome",
        element: <CarFund />,
      },
      {
        path: "earning/housefundincome",
        element: <HouseFund />,
      },
      {
        path: "earning/cptoconousincome",
        element: <CptoBonous />,
      },
      {
        path: "earning/pool1income",
        element: <Pool1 />,
      },
      {
        path: "earning/pool2income",
        element: <Pool2 />,
      },
      {
        path: "earning/pool3income",
        element: <Pool3 />,
      },
      {
        path: "earning/pool4income",
        element: <Pool4 />,
      },
      {
        path: "earning/pool5income",
        element: <Pool5 />,
      },
      {
        path: "earning/pool6income",
        element: <Pool6 />,
      },
      {
        path: "spinwheel/spin",
        element: <Spin />,
      },
      {
        path: "earning/rechargeincome",
        element: <RechargeIncome />,
      },
      {
        path: "earning/totalincome",
        element: <TotelIncome />,
      },
      {
        path: "earning/repurchasewithdraw",
        element: <Repurchasewith />,
      },
      {
        path: "earning/rewards",
        element: <Rewardincome />,
      },
      {
        path: "earning/royaltyincome",
        element: <RoyaltyIncome />,
      },
      {
        path: "pin/used",
        element: <UsePin />,
      },
      {
        path: "pin/received",
        element: <PinReceived />,
      },
      {
        path: "pin/requestreport",
        element: <PinRequestRepoer />,
      },
      {
        path: "pin/transferrequest",
        element: <PinTransfer />,
      },
      {
        path: "pin/transferreport",
        element: <PinTransferReport />,
      },
      {
        path: "pin/request",
        element: <PinRequest />,
      },
      {
        path: "pin/unused",
        element: <UnusedPin />,
      },
      {
        path: "vendors",
        element: <VendorsList />,
      },
      {
        path: "recharge/report",
        element: <RechargeReport />,
      },
      {
        path: "wallet/used",
        element: <UsedWallet />,
      },
      {
        path: "wallet/unused",
        element: <UnuseWallet />,
      },
      {
        path: "support",
        element: <Support />,
      },
      {
        path: "addpopup",
        element: <AddPopup />,
      },
      {
        path: "addbusiness",
        element: <AddBusiness />,
      },
      {
        path: "purchase/wltrecRpt",
        element: <WalletReceiveReport />,
      },
      {
        path: "purchase/wallettransferreport",
        element: <WalletTransferReport />,
      },
      {
        path: "purchase/offlinepurchase",
        element: <OfflinePurchase />,
      },
      {
        path: "purchase/order",
        element: <OrderDetails />,
      },
      {
        path: "purchase/wallettrnas",
        element: <WalletTransferReq />,
      },
      {
        path: "*",
        element: <Error404 />,
      },
    ],
  },
];
export default Routes;
