import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { MDBDataTable } from "mdbreact";
import { useParams } from "react-router";
import token from "../../../../Helper/Token";
import { humanReadableDate, humanReadableTimeAndDate } from "../../../../Helper/function";
import TablePlaceholder from "../../../Components/TablePlaceholder";

export default function FundRequesReportAdmin() {
  const [fundRequest, setFundRequest] = useState([]);
  const [loadingTable, setLoadingTable] = useState(false);

  const { status, payment_mode } = useParams();

  useEffect(() => {
    fetchRequestData();
  }, [status, payment_mode]);

  function fetchRequestData() {
    setLoadingTable(true);
    token
      .get(
        `/admin/get-fund-request/?status=${status}&payment_mode=${payment_mode}`
      )
      .then((res) => {
        if (res.data.status == "success") {
          setFundRequest(res.data.data);
        } else {
          toast.error(res?.data?.message, {
            position: "top-right",
            autoClose: 900,
          });
        }
        setLoadingTable(false);
      })
      .catch((err) => {
        toast.error("Internal Server Error.", {
          position: "top-right",
          autoClose: 900,
        });
      });
  }

  function updateFundRequest(requestId, response) {
    token.put(`/admin/get-fund-request`, { requestId, response })
      .then((res) => {
        if (res.data.status == "success") {
          toast.success(res?.data?.message, {
            position: "top-left",
            autoClose: 900,
          });
          fetchRequestData();
        } else {
          toast.error(res?.data?.message, {
            position: "top-right",
            autoClose: 900,
          });
        }
      })
      .catch((err) => {
        toast.error("Internal Server Error.", {
          position: "top-right",
          autoClose: 900,
        });
      });
  }

  const table = {
    columns: [
      {
        label: "S.No",
        field: "index",
        sort: "asc",
        width: 150,
      },

      {
        label: "User Id",
        field: "userId",
        sort: "asc",
        width: 150,
      },

      {
        label: "Name",
        field: "name",
        sort: "asc",
        width: 150,
      },

      {
        label: "Amount",
        field: "amount",
        sort: "asc",
        width: 150,
      },
      {
        label: "Converted Amount",
        field: "convertedAmount",
        sort: "asc",
        width: 150,
      },
      {
        label: "Transaction Id",
        field: "transactionId",
        sort: "asc",
        width: 150,
      },
      {
        label: "Request Date",
        field: "requestDate",
        sort: "asc",
        width: 150,
      },

      {
        label: "Remark",
        field: "remark",
        sort: "asc",
        width: 150,
      },
    ],

    rows: [],
  };

  if (status == 0) {
    table?.columns.push({
      label: "Action",
      field: "action",
      sort: "asc",
      width: 150,
    });
  }

  if (status != 0) {
    table?.columns.splice(6, 0, {
      label: "Respond Date",
      field: "respondDate",
      sort: "asc",
      width: 150,
    });
  }

  if (payment_mode == "usdt") {
    table?.columns.splice(3, 0, {
      label: "USDT Rate",
      field: "usdtRate",
      sort: "asc",
      width: 150,
    });
  }
  if (payment_mode == "upi") {
    table?.columns.splice(3, 0, {
      label: "Receiver Address",
      field: "receiveraddress",
      sort: "asc",
      width: 150,
    });
  }

  if (payment_mode == "tron") {
    table?.columns.splice(3, 0, {
      label: "Tron Rate",
      field: "kmtRate",
      sort: "asc",
      width: 150,
    });
  }

  fundRequest.map((elem, i) => {
    table.rows.push({
      index: i + 1,
      userId: elem?.userId?.userId,
      name: elem?.userId?.name,
      usdtRate: elem?.usdtRate + " / INR",
      kmtRate: elem?.kmtRate + " / INR",
      amount:
        payment_mode == "upi"
          ? `₹ ${elem.amount}`
          : payment_mode == "tron"
          ? elem.amount + " Tron"
          : payment_mode == "usdt"
          ? elem?.amount + " USDT"
          : null,
      convertedAmount:
        payment_mode == "tron"
          ? `₹ ${
              elem?.amount * elem?.kmtRate +
              (elem?.amount * elem?.kmtRate * 15) / 100
            }`
          : payment_mode == "usdt"
          ? `₹ ${
              elem?.amount * elem?.usdtRate +
              (elem?.amount * elem?.usdtRate) / 10
            }`
          : payment_mode == "upi"
          ? `₹ ${elem?.amount + (elem?.amount * 5) / 100}`
          : null,

      transactionId: elem.transactionId,
      requestDate: humanReadableDate
      (elem.createdAt),
      respondDate:
        elem.status != 0 ? humanReadableTimeAndDate(elem.updatedAt) : null,
      remark: elem.remark,
      receiveraddress: elem?.receiverAddress??'',
      action: (
        <>
          {" "}
          <button
            className="btn btn-success m-1"
            onClick={(e) => {
              e.preventDefault();
              updateFundRequest(elem._id, 1);
            }}
          >
            Accept
          </button>
          <button
            className="btn btn-danger m-1"
            onClick={(e) => {
              e.preventDefault();
              updateFundRequest(elem._id, -1);
            }}
          >
            Reject
          </button>
        </>
      ),
    });
  });

  return (
    <>
      <div class="page-titles">
        {status == 1 ? (
          <h1 className="text-light">
            Approved {payment_mode.toLocaleUpperCase()} Deposit Report
          </h1>
        ) : null}
        {status == 0 ? (
          <h1 className="text-light">
            Pending {payment_mode.toLocaleUpperCase()} Deposit Report
          </h1>
        ) : null}
        {status == -1 ? (
          <h1 className="text-light">
            Rejected {payment_mode.toLocaleUpperCase()} Deposit Report
          </h1>
        ) : null}
      </div>
      <div class="content-body">
        <div class="container-fluid">
          <div class="row ">
            <div class="col-lg-12">
              <div class="card">
                <div className="p-3">
                  {!loadingTable ? (
                    <div class="table table-responsive mbdtable">
                      <MDBDataTable bordered small data={table} />
                    </div>
                  ) : null}

                  {loadingTable ? (
                    <table class="table table-responsive">
                      <thead>
                        <tr className="border">
                          <th className="border" scope="col">
                            S.No
                          </th>
                          <th className="border" scope="col">
                            {" "}
                            User Id
                          </th>
                          <th className="border" scope="col">
                            Name
                          </th>{" "}
                          {payment_mode == "tron" ? (
                            <th className="border" scope="col">
                              Tron Rate
                            </th>
                          ) : null}
                          {payment_mode == "usdt" ? (
                            <th className="border" scope="col">
                              USDT Rate
                            </th>
                          ) : null}
                          <th className="border" scope="col">
                            Amount
                          </th>


                          {payment_mode == "tron" || payment_mode == "usdt" ? (
                            <th className="border" scope="col">
                              Converted Amount
                            </th>
                          ) : null}


                          <th className="border" scope="col">
                            Transaction Id
                          </th>
                          <th className="border" scope="col">
                            Request Date
                          </th>
                          {status != 0 ? (
                            <th className="border" scope="col">
                              Response Date
                            </th>
                          ) : null}
                          <th className="border" scope="col">
                            Remark
                          </th>
                          {status == 0 ? (
                            <th className="border" scope="col">
                              Action
                            </th>
                          ) : null}
                        </tr>
                      </thead>

                      <TablePlaceholder
                        colNum={payment_mode == "upi" ? 8 : 10}
                      />
                    </table>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
